import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List, OrderList } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="text-center">
                    Terms for Image upload
                </Title>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Terms of Use
                  </Title>
                  <Text variant="small">
                    What cannot be uploaded to Kesto.io servers:
                  </Text>
                  <List>
                    <li>
                        Copyrighted images if you don't own the copyright and are not licensed to do so.
                    </li>
                    <li>
                        Violence, "hate speech" (i.e. demeaning race, gender, age, religious or sexual orientation, etc.), or advocacy against any individual, group, or organization.
                    </li>
                    <li>
                        Images that are threatening, harassing, defamatory, or that encourage violence or crime.
                    </li>
                    <li>
                        Any images that might be illegal in the USA or EU, such as child sexual abuse, or public exposure ("doxxing").
                    </li>
                    <li>
                        Adult imagery is not allowed.
                    </li>
                  </List>
                    <Text variant="small" mb="5px">
                        If you are not sure whether the image you want to upload is allowed then do not upload that image. Uploaded images are checked by staff and images that violate terms will be removed without prior warning. This may also get you banned from our website.
                    </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                    <Title variant="card" fontSize="2rem" mb="30px">
                        Legalese (inspired by Imgur terms of use)
                    </Title>
                    <Text variant="small">
                        By uploading a file or other content or by making a comment, you represent and warrant to us that (1) doing so does not violate or infringe anyone else's rights; and (2) you created the file or other content you are uploading, or otherwise have sufficient intellectual property rights to upload the material consistent with these terms. With regard to any file or content you upload to the public portions of our site, you grant Kesto a non-exclusive, royalty-free, perpetual, irrevocable worldwide license (with sublicense and assignment rights) to use, to display online and in any present or future media, to create derivative works of, to allow downloads of, and/or distribute any such file or content, including embedded (hotlinked) into third-party websites otherwise not affiliated with Kesto. To the extent that you delete any such file or content from the public portions of our site, the license you grant to Kesto pursuant to the preceding sentence will automatically terminate, but will not be revoked with respect to any file or content Kesto has already copied and sublicensed or designated for sublicense.
                    </Text>
                    <Text variant="small">
                        By downloading an image or copying other user-generated content (UGC) from Kesto, you agree that you do not claim any rights to it. The following conditions apply:
                    </Text>
                    <List>
                        <li>
                            You may use UGC for personal, non-commercial purposes
                        </li>
                        <li>
                            You may use UGC for anything that qualifies as fair use under copyright law, for example journalism (news, comment, criticism, etc.), but please include an attribute ("Kesto" or "courtesy of Kesto") next to where it is displayed.
                        </li>
                        <li>
                            You may not use UGC for non-journalistic commercial purposes, except if the UGC items in question have been legally uploaded by you (i.e. you are the copyright holder), or if you have otherwise obtained a license from the copyright owner. Posting photos of goods you sell is okay; ripping off a competitor's catalogue is not.
                        </li>
                        <li>
                            Your use of UGC is at your own risk. KESTO MAKES NO WARRANTIES OF NON-INFRINGEMENT, and you will indemnify and hold Kesto harmless from any copyright infringement claims arising out of your use of the UGC.
                        </li>
                        <li>
                            You may not copy or use any portions of our site that are not UGC except within the limits of fair use.
                        </li>
                    </List>
                    <Text variant="small">
                        If you see anything on our site that you believe infringes your copyright rights, you may notify our Digital Millennium Copyright Act ("DMCA") agent by sending the following information:
                    </Text>
                    <OrderList>
                        <li>
                            Identification of the copyrighted work or works claimed to have been infringed. IMPORTANT: you must have a registered copyright for the work, or at least have filed with the Copyright Office (http://www.copyright.gov/eco/) an application to register a copyright for the work. DMCA notifications based on unregistered works are not valid.
                        </li>
                        <li>
                            Identification of the material on our servers that is claimed to be infringing and that is to be removed, including the URL or other information to enable us to locate the material.
                        </li>
                        <li>
                            A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by you as copyright owner, or by your agent, or by law.
                        </li>
                        <li>
                            A statement that the information in your notice is accurate, and under penalty of perjury, that you are the owner (or authorized to act on behalf of the owner) of the exclusive copyright right that is allegedly being infringed.
                        </li>
                        <li>
                            Your physical or electronic signature, or of someone authorized to act on your behalf.
                        </li>
                        <li>
                            Instructions on how we may contact you: preferably email, but also address and phone.
                        </li>
                    </OrderList>

                    <Text variant="small" mb="10px">
                        Because all DMCA notifications must be based on a work for which the copyright is registered with the Copyright Office (or for which registration has been applied for), and because a high percentage of DMCA takedown notices are not valid, it will speed our investigation of your DMCA notice if you attach to it a copy of your copyright registration, or registration application, for the work. DMCA notices should be sent using the appropriate method in the Contacts section of our site or to support@kesto.io.
                    </Text>
                    <Text variant="small" mb="10px">
                        Although of course we strive to make Kesto as dependable as possible, Kesto's services are provided on an AS IS – WITH ALL FAULTS basis. Your use of our service is entirely at your own risk. We do not guarantee the availability of our service at any given time, or the reliability of our service when it is running. We do not guarantee the integrity of, or the continued availability of, files on our servers. Whether we make backups, and if so, whether restoration of those backups will be available to you, is at our discretion. KESTO DISCLAIMS ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF FITNESS AND MERCHANTABILITY. NOTWITHSTANDING ANYTHING ELSE STATED IN THESE TERMS, AND IRRESPECTIVE OF WHETHER KESTO TAKES OR DOES NOT TAKE MEASURES TO REMOVE INAPPROPRIATE OR HARMFUL CONTENT FROM ITS SITE, KESTO HAS NO DUTY TO MONITOR ANY CONTENT ON ITS SITE. KESTO DOES NOT ASSUME RESPONSIBILITY FOR THE ACCURACY, APPROPRIATENESS, OR HARMLESSNESS OF ANY CONTENT APPEARING ON KESTO.IO THAT IS NOT PRODUCED BY KESTO, INCLUDING BUT NOT LIMITED TO USER CONTENT, ADVERTISING CONTENT, OR OTHERWISE.
                    </Text>
                    <Text variant="small" mb="10px">
                        Your sole remedy for the loss of any services and/or of any images or other data you may have stored on Kesto's service is to discontinue your use of our service. KESTO WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, KESTO'S SERVICES, EVEN IF KESTO HAS BEEN ADVISED OF OR REASONABLY SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. NO CAUSE OF ACTION ARISING OUT OF YOUR USE OF KESTO'S SERVICES MAY BE BROUGHT MORE THAN ONE YEAR AFTER IT OCCURS.
                    </Text>
                    <Text variant="small" mb="10px">
                        YOU WILL INDEMNIFY AND HOLD KESTO AND ALL OF ITS PERSONNEL HARMLESS FROM ALL LOSS, LIABILITY, CLAIMS, DAMAGES AND EXPENSES, INCLUDING REASONABLE ATTORNEY FEES, ARISING OUT OF OR RELATED TO YOUR VIOLATION OF THESE TERMS, YOUR INFRINGEMENT OF ANY THIRD PARTY'S RIGHTS, AND ANY HARM CAUSED TO ANY THIRD PARTY AS A RESULT OF YOUR UPLOADING OF FILES, COMMENTS, OR ANYTHING ELSE TO OUR SERVERS.
                    </Text>
                    <Text variant="small">
                        "You" refers to any person who has consented to these terms or has become contractually bound to them, whether such person is identified or not at the time. "Kesto" or "we" refers to the legal entity controlling the Kesto project, its successors and assigns. If any part of these terms is invalid, the remaining provisions will be unaffected. These Terms of Use constitute the entire agreement among the parties relating to this subject matter, and they will continue to govern any issues that arise out of your use of Kesto's services even after you discontinue using them. We may revise these terms from time to time without notice
                    </Text>
                </Box>
                
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
